import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Email } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import GitHubIcon from "@mui/icons-material/GitHub";
import { HashLink } from "react-router-hash-link";

const Footer = () => {

  const instaLink = "https://www.instagram.com/adhyaay_24?igsh=MWlxenJ3aWc5aGozeA=="
  const ytLink = "https://youtube.com/@adhyaay22"
  const gitLink = "https://github.com/lawaniya-yash/adhyaay-latest"

  const handleInstaClick = () => {
    window.open(instaLink, '_blank');
  }

  const handleYtClick = () => {
    window.open(ytLink, '_blank');
  }

  const handleGitClick = () => {
    window.open(gitLink, '_blank');
  }

  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);



  return (
    <HashLink smooth to="/#contact">
      <div
        className="footer min-h-[250px] border-t-2 border-[#FFD38C]"
        id="contact"
        style={{ background: "rgb(26,26,33)" }}
      >
        <div
          className="footer-trailer"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <iframe
            width="250"
            height="150"
            style={{
              borderRadius: "10px",
              border: "0px",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
            }}
            src="https://www.youtube.com/embed/66LEKYzwido"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="footer-contact flex flex-col sm:flex-row gap-[20px]">
          <div className="follow" data-aos="fade-up">
            <h3 style={{ color: "gold", fontSize: 25, marginBottom: "7px", fontFamily: "monospace" }}>
              Keep Connected
            </h3>
            <div className="follow-app">
              <Link
                onClick={handleYtClick}
                style={{ color: "white" }}
                data-aos="fade-up"
              >
                <YouTubeIcon style={{ color: "crimson", fontSize: 35 }} />
              </Link>
              <p onClick={handleYtClick} style={{ color: "white", marginLeft: "7px" }}>
                Subscribe us on Youtube{" "}
              </p>
            </div>
            <div className="follow-app">
              <Link
                onClick={handleInstaClick}
                style={{ color: "white" }}
                data-aos="fade-up"
              >
                <InstagramIcon style={{ color: "darkmagenta", fontSize: 35 }} />
              </Link>
              <p onClick={handleInstaClick} style={{ color: "white", fontsize: "10" }}>
                &nbsp; Follow us on Instagram{" "}
              </p>
            </div>
          </div>
          <div className="email" data-aos="fade-up">
            <div className="follow-app">
              <Email style={{ color: "Black", fontSize: 25 }} />
              <p style={{ color: "Gold", fontSize: 25, marginLeft: "7px", fontFamily: "monospace" }}>
                Email Us{" "}
              </p>
            </div>
            <a
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "16px",
                marginLeft: "20px",
                fontFamily: "monospace"
              }}
              href="mailto:culturalsubcouncil@hbtu.ac.in"
            >
              &nbsp;culturalsubcouncil@hbtu.ac.in
            </a>
            <div className="follow-app mt-[10px]" data-aos="fade-up">
              <CallIcon style={{ color: "blue" }} />
              <p
                style={{
                  marginTop: "6px",
                  color: "gold",
                  fontSize: "25px",
                  fontFamily: "monospace",
                  marginLeft: "5px",
                }}
              >
                Contact Us
              </p>
            </div>
            <ul
              style={{
                marginLeft: "28px",
                color: "white",
                fontSize: "15px",
                marginTop: "6px",
                fontFamily: "monospace"
              }}
            >
              <li >Nishchal Nigam : 8273446300 </li>
              <li>Harsh Maddheshiya : 9696151977 </li>
              <li>Nishant Gola : 8279987490 </li>
            </ul>
          </div>
        </div>
        <Link onClick={handleGitClick} data-aos="fade-up">
          <GitHubIcon style={{ color: "#eee8aa", fontSize: 30 }} />
        </Link>
      </div>
    </HashLink>
  );
};

export default Footer;