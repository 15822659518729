import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import { useMediaQuery } from "@react-hook/media-query";
import l1 from "../assets/logos/realme Logo-01.jpg";
import l10 from "../assets/logos/bbbb.jpg";
import l16 from "../assets/logos/PRISTINE.png";
import l4 from "../assets/logos/Storia_logo-01.png";
import l12 from "../assets/logos/bench store.png";
import l5 from "../assets/logos/bw.png";
import l7 from "../assets/logos/kamd.jpeg";
import l8 from "../assets/logos/mm.jpg";
import l9 from "../assets/logos/pb society.png";
import l2 from "../assets/logos/rim jhim.jpg";
import l11 from "../assets/logos/ua.png";
import l6 from "../assets/logos/fm.png";
import l3 from "../assets/logos/download.png";
import tsh from "../assets/logos/tsh.png";
import l13 from "../assets/logos/trends.png";
import l14 from "../assets/logos/reliance-digital-logo-vector.png";
import l15 from "../assets/logos/NAMASTE.jpg";
import "./Sponsers.css";

const Sponsors = () => {
  const logos = [l8, l9, l10, l11, l12, l13, l14, l15];
  const isWide = useMediaQuery("(min-width: 786px)");

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return isWide ? (
    <div className="Sponsor">
      <div className="bg-gradient-to-br from-black to-white-900 pt-32 min-h-screen flex flex-col items-center overflow-hidden">
        <div
          className="flex justify-center px-10 py-20 overflow-hidden "
          style={{ flexWrap: "wrap" }}
        >
          {[
            { src: l1, alt: "Realme Logo" },
            { src: l2, alt: "Rim Jhim" },
            { src: l3, alt: "Download" },
            { src: l4, alt: "Download" },
          ].map((logo, index) => (
            <Parallax translateX={["-100px", "100px"]} key={index}>
              <div className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="max-w-full max-h-full object-cover rounded-full"
                  data-aos="fade-up"
                />
              </div>
            </Parallax>
          ))}
        </div>

        <div
          className="flex justify-center px-10 py-20 overflow-x"
          style={{ flexWrap: "wrap" }}
        >
          {[
            { src: l5, alt: "Download" },
            { src: l6, alt: "Download" },
            { src: l7, alt: "Download" },
            { src: l8, alt: "Download" },
          ].map((logo, index) => (
            <Parallax translateX={["50px", "-50px"]} key={index}>
              <div className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="max-w-full max-h-full object-cover rounded-full"
                  data-aos="fade-up"
                />
              </div>
            </Parallax>
          ))}
        </div>
        <div
          className="flex justify-center px-10 py-20 overflow-x"
          style={{ flexWrap: "wrap" }}
        >
          {[
            { src: l9, alt: "Download" },
            { src: l10, alt: "Download" },
            { src: l11, alt: "Download" },
            { src: l12, alt: "Download" },
          ].map((logo, index) => (
            <Parallax translateX={["-100px", "100px"]} key={index}>
              <div className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="max-w-full max-h-full object-cover rounded-full"
                  data-aos="fade-up"
                />
              </div>
            </Parallax>
          ))}
        </div>
        <div
          className="flex justify-center px-10 py-20 overflow-x"
          style={{ flexWrap: "wrap" }}
        >
          {[
            { src: l13, alt: "Download" },
            { src: l14, alt: "Download" },
            { src: l15, alt: "Download" },
            { src: l16, alt: "Download" },
          ].map((logo, index) => (
            <Parallax translateX={["100px", "-100px"]} key={index}>
              <div className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="max-w-full max-h-full object-cover rounded-full"
                  data-aos="fade-up"
                />
              </div>
            </Parallax>
          ))}
        </div>
      </div>
    </div>
  ) : 
  (

    <div className="Sponsor">
    <div className="bg-gradient-to-br from-black to-white-900 pt-32 min-h-screen flex flex-col items-center overflow-hidden">
      <div
        className="flex justify-center px-10 py-20 overflow-hidden "
        style={{ flexWrap: "wrap" }}
      >
        {[
          { src: l1, alt: "Realme Logo" },
          { src: l2, alt: "Rim Jhim" },
          { src: l3, alt: "Download" },
          { src: l4, alt: "Download" },
        ].map((logo, index) => (
          <div
            className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center"
            style={{ width: "200px", height: "200px" }}
            key={index}
            data-aos="fade-up"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-w-full max-h-full object-cover rounded-full"
            />
          </div>
        ))}
      </div>

      <div
        className="flex justify-center px-10 py-20 overflow-x"
        style={{ flexWrap: "wrap" }}
      >
        {[
          { src: l5, alt: "Download" },
          { src: l6, alt: "Download" },
          { src: l7, alt: "Download" },
          { src: l8, alt: "Download" },
        ].map((logo, index) => (
          <div
            className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center"
            key={index}
            style={{ width: "200px", height: "200px" }}
            data-aos="fade-up"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-w-full max-h-full object-cover rounded-full"
            />
          </div>
        ))}
      </div>
      <div
        className="flex justify-center px-10 py-20 overflow-x"
        style={{ flexWrap: "wrap" }}
      >
        {[
          { src: l9, alt: "Download" },
          { src: l10, alt: "Download" },
          { src: l11, alt: "Download" },
          { src: l12, alt: "Download" },
        ].map((logo, index) => (
          <div
            className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center"
            key={index}
            style={{ width: "200px", height: "200px" }}
            data-aos="fade-up"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-w-full max-h-full object-cover rounded-full"
            />
          </div>
        ))}
      </div>
      <div
        className="flex justify-center px-10 py-20 overflow-x"
        style={{ flexWrap: "wrap" }}
      >
        {[
          { src: l13, alt: "Download" },
          { src: l14, alt: "Download" },
          { src: l15, alt: "Download" },
          { src: l16, alt: "Download" },
        ].map((logo, index) => (
          <div
            className="sponserImg mx-4 my-4 overflow-hidden rounded-full bg-white p-2 flex justify-center items-center"
            key={index}
            style={{ width: "200px", height: "200px" }}
            data-aos="fade-up"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-w-full max-h-full object-cover rounded-full"
            />
          </div>
        ))}
      </div>
    </div>
  </div>



  );
};

export default Sponsors;
