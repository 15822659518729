// Merch.js
import { Zoom } from "react-swift-reveal";
import Card from "./Card";
import "./Merch.scss";
import AOS from 'aos';
import React, { useEffect } from "react";
import 'aos/dist/aos.css'; 
import Tshirt1Image from "../assets/Tshirt1.png";
import Tshirt2Image from "../assets/Tshirt.png";
import Tshirt3Image from "../assets/offer.jpeg";


const Merch = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const cardsData = [
    {
      name: "For 1st & 2nd Year",
      detail: "A high-top version of the classic T-shirt at just 499/-",
      gradientColor: "linear-gradient(68.4deg, rgb(248, 182, 204) 0.5%, rgb(192, 198, 230) 49%, rgb(225, 246, 240) 99.8%)",
      image: Tshirt1Image,
      buyLink: "https://forms.gle/FJwYcSDAsyMnM8te9",
    },
    {
      name: "For Final Year",
      detail: "Bold, high-top, and ready for the fest spotlight – grab yours now at just 599/-",
      gradientColor: "linear-gradient(109.6deg, rgb(148, 233, 194) 11.2%, rgb(224, 235, 186) 91.1%)",
      image: Tshirt2Image,
      buyLink: "https://forms.gle/7eiRurb4EGLdWwXt9",
    },
    {
      name: "5 T-shirt Offer For Flat 2024/-",
      detail: "Unleash the style with our combo offer of 5 – because one is never enough!",
      gradientColor: "linear-gradient(68.4deg, rgb(248, 182, 204) 0.5%, rgb(192, 198, 230) 49%, rgb(225, 246, 240) 99.8%)",
      image: Tshirt3Image,
      buyLink: "https://forms.gle/YKvnJJn9miW66UEg7",
    },
  ];

  return (
    <div
      className="mine"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
      }}
    >
      <h1 className="heading">Merchandise</h1>

      <div className="t-box" data-aos="fade-up">
        {cardsData.map((data, index) => (
           <Zoom key={index}> 
           <Card
             name={data.name}
             detail={data.detail}
             gradientColor={data.gradientColor}
             image={data.image}
             buyLink={data.buyLink}
           />
         </Zoom>
        ))}
      </div>
    </div>
  );
};

export default Merch;