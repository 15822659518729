import { Box, Typography } from "@mui/material";
import Team from "../components/Carousel";

const About = () => {
  return (
    <div className="body">
      <Typography
        variant="h2"
        style={{
          paddingTop: "12px",
          marginLeft: "20px",
          fontFamily: "serif",
          color: "#f9fd50",
          backgroundColor: "black",
          textAlign: "center",
        }}
      >
        About
      </Typography>
      <Typography
        style={{
          fontFamily: "georgia",
          color: "#f9fd50",
          paddingLeft: 40,
          paddingRight: 30,
          paddingTop: 20,
        }}
      >
        HBTU Kanpur not only fosters academic excellence, but also promotes an
        environment full of abundant opportunities for its students to showcase 
        their inner talent. The Cultural Sub- Council of the University presents 
        ADHYAAY, a three days annual celebration of cultural diversity, artistic 
        expression, and community spirit. The fest aims to serve a vibrant platform 
        for fostering interethnic exchange, innovation and inclusivity, and encouraging 
        students to explore themselves and the college life. 
        We invite you to join us on this journey of discovery, connection, and celebration.
      </Typography>
    </div>
  );
};

export default About;
