// Card.js
import React, { useState, useRef } from "react";
import "./Card.css";
import { Zoom } from "react-swift-reveal";

export default function Card({ name, detail, gradientColor, image, buyLink }) {
  const [xRotation, setXRotation] = useState(0);
  const [yRotation, setYRotation] = useState(0);
  const cardRef = useRef(null);
  const imgRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const sizesboxRef = useRef(null);
  const purchaseRef = useRef(null);

  function handleMouseMove(event) {
    const card = cardRef.current;

    if (!card) {
      return;
    }

    const { offsetWidth: width, offsetHeight: height } = card;
    const { clientX, clientY } = event;
    const x = clientX - card.offsetLeft - width / 2;
    const y = clientY - card.offsetTop - height / 2;
    var mult = 40;
    setXRotation((y / height) * mult);
    setYRotation((x / width) * mult);
  }

  function handleMouseEnter() {
    const title = titleRef.current;
    const sizesBox = sizesboxRef.current;
    const purchase = purchaseRef.current;
    const desc = descRef.current;
    title.style.transform = "translateZ(150px)";
    sizesBox.style.transform = "translateZ(100px)";
    purchase.style.transform = "translateZ(75px)";
    desc.style.transform = "translateZ(75px)";
  }

  function handleMouseLeave() {
    setXRotation(0);
    setYRotation(0);

    const img = imgRef.current;
    const title = titleRef.current;
    const sizesBox = sizesboxRef.current;
    const purchase = purchaseRef.current;
    title.style.transform = "translateZ(0px)";
    img.style.transform = "translateZ(0px) rotateZ(0deg)";
    sizesBox.style.transform = "translateZ(0px)";
    purchase.style.transform = "translateZ(0px)";
  }

  function handleButtonClick() {
    window.open(buyLink, "_blank");
  }

  return (
    <Zoom>
      <div
        className="card"
        ref={cardRef}
        style={{
          transform: `rotateX(${xRotation}deg) rotateY(${yRotation}deg)`,
          background: gradientColor || "linear-gradient(to right, #C8D6E5 0%, #8AE9B3 50%)",
        }}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          ref={imgRef}
          src={image || ""}
          alt="Merchandise"
          className="sneaaker-img"
        />

        <h1 className="title" ref={titleRef}>
          {name}
        </h1>
        <p className="detail" ref={descRef}>
          {detail}
        </p>
        <ul className="sizes-box" ref={sizesboxRef}>
          <li>S</li>
          <li>M</li>
          <li>L</li>
          <li>XL</li>
        </ul>
        <div className="button-box" ref={purchaseRef}>
          <button className="purchase" onClick={handleButtonClick}>
            Buy Our Merch
          </button>
        </div>
      </div>
    </Zoom>
  );
}