import EventCard from "./EventCard";
import openmic from "../assets/open mic.jpg";
import soty from "../assets/events/soty.jpg";
import pia from "../assets/events/pia.jpg";
import se from "../assets/events/se.jpg";
import bts from "../assets/events/bts.jpg";
import gs from "../assets/events/gs.jpg";
import dj from "../assets/events/dj.jpg";
import hgt from "../assets/events/hgt.jpg";
import bd from "../assets/events/bd.jpg";
import cn from "../assets/events/cn.jpg";
import sufi from "../assets/sufi.jpg";
import mask from "../assets/mask.jpg";
import guess from "../assets/guess.jpg";
import talent from "../assets/talent.jpg";
import "./Event.scss";
import AOS from 'aos';
import React, { useEffect, useState } from "react";
import 'aos/dist/aos.css';
import './Events.css';

const day1 = [
  {
    name: "STUDENT OF THE YEAR",
    des: "Join us for an exhilarating event as we crown the most exceptional and talented student of the year! The Student of the Year is a fiesta of extraordinary performances and enthusiasm. So gear up for an unforgettable experience as we welcome you in round 1 of the Student of the Year competition. Throughout the event, participants will get a stage to shine and showcase their inner artist through various rounds.We promise you a day full of absolute blast with energetic performances and an incredible atmosphere!",
    img: soty,
    id: 1
  },
  {
    name: "PAINT IT ALL",
    des: "Is your creativity flowing out from a paintbrush? Unleash your inner Picasso and let your imagination run wild in our next event:  'Paint it all'  This event invites artists to express themselves through the vibrant medium of paint on the big canvas.Whether you're a seasoned painter or just starting out, this competition is a chance to showcase your unique style, techniques, and vision. We invite you to the platform where you can personalize the campus by your artistic passion.",
    img: pia,
    id: 2
  },
  {
    name: "Sanskritik Ekta",
    des: "Does the sound of 'ghunghroo' resonates with your heartbeat and the classical rhythm makes you groove on the stage? Get ready as ADHYAAY'24 presents you Saanskritic Ekta, where you will get to experience the elegance of Indian classical dances. Immerse yourself in the rich culture as talented dancers and bring to life the intricate movements and storytelling of Bharatanatyam, Kathak, Kuchipudi, and more. Join us for an evening of captivating performances, celebrating the timeless beauty and tradition of Indian classical dance.",
    img: se,
    id: 3
  },
  {
    name: "BEAT THE STREET",
    des: "Get ready to groove and battle it out on the dance floor! Join us on day 1 of an electrifying dance battle where talent meets rhythm. From breakdancing to hip-hop to freestyle, let your moves do the talking as you compete for the title of dance champion. With a lot of excitement and intense competition, this is an event you won't want to miss. Bring your A-game and let the dance floor ignite with your passion and energy!",
    img: bts,
    id: 4
  },
  {
    name: "SUFI NIGHT",
    des: "Feel the peace in the mesmerizing allure of Sufi music at our enchanting Sufi Night. Immerse yourself in the mystical melodies of classical Sufi music as it transcends time and space. Let the soulful tunes transport you to a realm of spiritual bliss. With captivating performances of singers and the best of tabla, this evening promises to be a celebration of divine harmony and cultural richness. Join us for an unforgettable journey into the depth of divine music with Sufi Night!",
    img: sufi,
    id: 5
  },
];
const day2 = [
  {
    name: "STUDENT OF THE YEAR",
    des: "Join us for an exhilarating event as we crown the most exceptional and talented student of the year! The Student of the Year is a fiesta of extraordinary performances and enthusiasm. So gear up for an unforgettable experience as we welcome you in round 1 of the Student of the Year competition. Throughout the event, participants will get a stage to shine and showcase their inner artist through various rounds.We promise you a day full of absolute blast with energetic performances and an incredible atmosphere!",
    img: soty,
    id: 1
  },
  {
    name: "MISMATCHED",
    des: "Embark on a  journey with none other than your favorite senior to be your partner at our dynamic 'MISMATCHED DANCE' event. Set the stage high with electrifying energy, vibrant music, and unmatched camaraderie. Harmonise with your companion as you groove to beat with someone from a different year So, it's time to break the barrier and Create enduring memories on the dance floor",
    img: openmic,
    id: 6
  },
  {
    name: "BOLLYWOOD DAY",
    des: `Step into the glitz and glamour of Bollywood at our electrifying Bollywood Night! Dress up and unleash your inner star as you embody your favorite Bollywood icon.whether it's the timeless elegance of Madhuri Dixit from "Dil To Pagal Hai" or the charismatic charm of Shah Rukh Khan from DDLJ. From dazzling sarees to dapper suits, let your style shine on the red carpet. Get ready to dance the night away to iconic Bollywood tunes and create unforgettable memories under the sparkling lights of our Bollywood extravaganza!`,
    img: sufi,
    id: 7
  },
  {
    name: "ROADIES",
    des: "Get ready for an adrenaline-fueled experience like no other as we bring the spirit of Roadies to our college level competition! Prepare to push your limits and test your mettle through thrilling challenges that will challenge your physical strength, mental agility, and teamwork skills. Brace yourself for an action-packed event filled with excitement, drama, and unforgettable moments as you compete to prove yourself as the ultimate college Roadie",
    img: talent,
    id: 8
  },
  {
    name: "MASQUERADE PARTY",
    des: "Prepare for an electrifying EDM Night  Featuring top DJs, mesmerizing visuals, and non-stop dance vibes, it's the ultimate party experience. With pulsating beats and state-of-the-art effects, the dance floor will be alive all night. Don't miss out on the most exciting event of the year – get ready to dance the night away.",
    img: mask,
    id: 9
  },
  {
    name: "GLAMOUR STROUT",
    des: "Get ready to shine on stage at our college's highly anticipated Stage Performance Competition! Showcase your talent and creativity in front of a live audience as you perform theater plays, dance routines, musical acts, or any other form of artistic expression. Whether you're a seasoned performer or stepping onto the stage for the first time, this competition is your chance to captivate the crowd and leave a lasting impression. Show us what you've got and let your talent take center stage",
    img: gs,
    id: 10
  },
  {
    name: "EDM NIGHT - LET THE BEAT DROP",
    des: "Prepare for an electrifying EDM Night  Featuring top DJs, mesmerizing visuals, and non-stop dance vibes, it's the ultimate party experience. With pulsating beats and state-of-the-art effects, the dance floor will be alive all night. Don't miss out on the most exciting event of the year – get ready to dance the night away.",
    img: dj,
    id: 11
  },
];
const day3 = [
  {
    name: "STUDENT OF THE YEAR",
    des: "Join us for an exhilarating event as we crown the most exceptional and talented student of the year! The Student of the Year is a fiesta of extraordinary performances and enthusiasm. So gear up for an unforgettable experience as we welcome you in round 1 of the Student of the Year competition. Throughout the event, participants will get a stage to shine and showcase their inner artist through various rounds.We promise you a day full of absolute blast with energetic performances and an incredible atmosphere!",
    img: soty,
    id: 1
  },
  {
    name: "BEAT THE STREET",
    des: "Get ready to groove and battle it out on the dance floor! Join us on day 1 of an electrifying dance battle where talent meets rhythm. From breakdancing to hip-hop to freestyle, let your moves do the talking as you compete for the title of dance champion. With a lot of excitement and intense competition, this is an event you won't want to miss. Bring your A-game and let the dance floor ignite with your passion and energy!",
    img: bts,
    id: 4
  },
  {
    name: "Talent Fiesta",
    des: "Get ready to witness the magic unfold at 'HBTU Got Talent'. From mesmerizing melodies to breathtaking performances, Join us as we celebrate the vibrant diversity of our community through mesmerizing performances, captivating displays of art, and unforgettable cultural experiences🎭✨. From dance to music, poetry to painting, this fiesta promises to be an extravaganza of talent like never before. Get ready to be amazed and inspired!",
    img: hgt,
    id: 12
  },
  {
    name: "Blind Date",
    des: "Embark on a journey where connections are forged through shared experiences, laughter, and cultural wonders. Join us for an evening filled with surprises, music, and delightful conversations.From captivating conversations to unexpected connections, Don't miss out on the chance to encounter something extraordinary! Save the date and let destiny guide you to new friendships and unforgettable memories.It's an evening you won't want to miss!",
    img: bd,
    id: 13
  },
  {
    name: "Stage Performance",
    des: "Get ready to shine on stage at our college's highly anticipated Stage Performance Competition! Showcase your talent and creativity in front of a live audience as you perform theater plays, dance routines, musical acts, or any other form of artistic expression. Whether you're a seasoned performer or stepping onto the stage for the first time, this competition is your chance to captivate the crowd and leave a lasting impression. Show us what you've got and let your talent take center stage",
    img: guess,
    id: 14
  },
  {
    name: "Celebrity Night",
    des: "Embark on a journey where connections are forged through shared experiences, laughter, and cultural wonders. Join us for an evening filled with surprises, music, and delightful conversations.From captivating conversations to unexpected connections, Don't miss out on the chance to encounter something extraordinary! Save the date and let destiny guide you to new friendships and unforgettable memories.It's an evening you won't want to miss!",
    img: cn,
    id: 15
  },
];
const Events = () => {
  const [events, setEvents] = useState([...day1, ...day2, ...day3]);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="Eventu">
      <div
        style={{

          objectFit: "scale-down",
          display: "flex",
          flexDirection: "column",
          paddingTop: "180px",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
          paddingBottom: '60px',
        }}
      >
        <div style={{
          display: 'flex',
          gap: '25px'
        }}>
          <button className="button-28" onClick={(e) => { setEvents(day1); }} role='button'>Day 1</button>
          <button className="button-28" onClick={(e) => { setEvents(day2); }} role='button'>Day 2</button>
          <button className="button-28" onClick={(e) => { setEvents(day3); }} role='button'>Day 3</button>
        </div>
        <div
          id="#eventz"
          style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", paddingBottom: '50px' }}
        >
          {events.map((ev) => (

            <EventCard name={ev.name} des={ev.des} img={ev.img} id={ev.id} />
          ))}
        </div>

        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px',
        }}>
          <h1 className="bebas-neue-regular" style={{ fontSize: '60px', fontWeight: '300', color: '#EEE8AA' }}>RuleBook</h1>
          <p style={{ color: ' #ADD8E6', fontSize: '23px', paddingBottom: '40px' }}>"<strong>The official rulebook from Adhyaay is here!</strong> Get all the details about events and how to participate"</p>
          <iframe src="https://drive.google.com/file/d/1L0BIqWqS92xJe9hntUnVS5nGALrYDwVS/preview" width="100%" height="500px" allow="autoplay" style={{ maxWidth: '900px' }}></iframe>
        </div>
      </div>
    </div>);
};

export default Events;
