/* eslint-disable jsx-a11y/alt-text */
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import insta from "../assets/insta.png";
import linkedin from "../assets/linkedin.png";
import styles from "../Styles/carousel.module.css";
import indman from "../assets/man.jpg";
import { useEffect, useRef } from "react";

const Carousel = ({ arr, shouldAnimate }) => {

  const containerRef = useRef(null);

  useEffect(() => {
    if (shouldAnimate) {
      const teamCardWidth = 300;
      const intervalId = setInterval(() => {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            left: containerRef.current.scrollLeft + teamCardWidth,
            behavior: "smooth",
          });

          if (
            containerRef.current.scrollLeft +
            containerRef.current.clientWidth >=
            containerRef.current.scrollWidth
          ) {
            containerRef.current.scrollTo({
              left: 0,
              behavior: "auto",
            });
          }
        }
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [shouldAnimate]);


  return (
    <div

      className={styles.team}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    ref={containerRef}
    >
      {arr.map((profile, i) => (
        <div
          key={i}

style={{
  backgroundImage: `url(https://drive.google.com/thumbnail?id=${profile.IMAGE_ID}&sz=w1000)`,
  minWidth: 275,
  height: 325,
  margin: 30,
  transform: "scale(1)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: 30,
}}




          className={styles.team_card}

        >
          <div
            style={{
              backgroundColor: "black",
              opacity: 0.6,
              position: "absolute",
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 275,
              flexDirection: "column",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            className={styles.team_box}

          >
            <Typography
              variant="h6"
              color={"#ffd38c"}
              style={{
                textAlign: "center",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            >
              {profile.Designation}
            </Typography>
            <Typography
              variant="h6"
              color={"#ffd38c"}
              style={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              {profile["Name:"]}
            </Typography>
            <div className={`flex items-center gap-x-5 mt-3 ${styles.team_name}`}>
              <Link to={profile["Instagram :"]}>
                <img src={insta} width={30} />
              </Link>
              <Link to={profile["Linkedln :"]}>
                <img src={linkedin} width={30} />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
