/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import back from "../assets/background.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import bg from "../assets/bg.png";
import "./Home.scss";
import bgv from "../assets/bg-video.mp4";
import bgMobile from "../assets/bgMobile.png";
import logo from "../assets/logo.jpg";
import darshan from "../assets/darshan.jpg";
import darshanText from "../assets/darshanText.jpeg";
import rhea from "../assets/rheaText.jpeg";
import rhe from "../assets/rhe.jpg";
import djsins from "../assets/djsins.png";
import sins from "../assets/sins.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/Share';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp from 'react-countup';

import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from '@react-hook/media-query';
import YouTubeIcon from "@mui/icons-material/YouTube";


const Home = () => {


  const [isInViewport, setIsInViewport] = useState(false);
  const footerRef = useRef();
  const isWide = useMediaQuery('(min-width: 786px)');
  const instaLink = "https://www.instagram.com/adhyaay_24?igsh=MWlxenJ3aWc5aGozeA=="
  const ytLink = "https://youtube.com/@adhyaay22"
  const whatsup = "https://whatsapp.com/channel/0029VaQ7OuHHLHQRYWTv271A"

  const handleInstaClick = () => {
    window.open(instaLink, '_blank');
  }
  const handleYtLink = () => {
    window.open(ytLink, '_blank');
  }
  const handleWhatsupLink = () => {
    window.open(whatsup, '_blank');
  }


  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );
  const Button = ({ children }) => {
    return (
      <button className="button-style">
        {children}
      </button>
    );
  };

  useEffect(() => {
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsInViewport(entry.isIntersecting);
    }, options);

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <>




      <div
       className="flex justify-center min-h-screen bg-cover pb-20" style={{backgroundColor:"black" ,opacity:"0.99"}}>       
      
        <video autoPlay muted loop id="myVideo" className="absolute top-0 left-0 w-full h-full object-cover">
          <source src={bgv} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        

          <div className="mt-[140px] flex flex-col z-9 items-center" >
            <img src={logo} width={300} className="logo" data-aos-duration="2000" data-aos="fade-up" />


            <p className="text-center text-white text-[35px] lg:text-[70px]  font-bold" style={{ fontFamily: "georgia"}}>
              <p className="name" >ADHYAAY'24</p>
            </p>
            <p className="text-white text-center text-[15px] sm:text-[3px] lg:text-[1.5rem]">
              CULTURAL SUB-COUNCIL ,  <span className="extra">USAC HBTU </span>
            </p>

            <a href="#about-section" className="button1">
              <Button style={{ fontFamily: "sans-serif" }}><b>Let's Begin</b></Button>

            </a><div class="Nichewala">
              <div class="Date">
                <AccessTimeIcon style={{ color: "orange" }} />&nbsp; Date: 13-15 April
              </div>
              <div class="Location" >
                <LocationOnIcon style={{ color: "orange" }} />Venue: HBTU East Campus
              </div>
              <div class="Share" >
                <ShareIcon style={{ color: "orange" }} />
                Share it on : &nbsp;
                <WhatsAppIcon onClick={handleWhatsupLink} style={{ color: "white", fontSize: "30px", cursor: "pointer" }} />
                <YouTubeIcon onClick={handleYtLink} style={{ color: "white", fontSize: "30px", cursor: "pointer" }} />
                <InstagramIcon onClick={handleInstaClick} style={{ color: "white", fontSize: "30px", cursor: "pointer" }} />
              </div>
            </div>

            {/* <Link to="https://imjo.in/RjAkfq">
            <button
              className="text-[#FFD38C] mt-[70px] border-2 py-4 px-5 border-[#FFD38C] font-bold"
              style={{ borderRadius: "24px 16px 24px 0px" }}
               onClick={() => navigate("/register")}
            >
              REGISTER NOW
            </button>
          </Link> */}
          </div>
          </div>

      <div className="Home">
        <div
          className="border-t-2 border-b-2 border-[#FFD38C] flex justify-between items-center bg-black min-w-full py-10 px-[10px] md:px-20"
          id="about-section" data-aos="fade-up" data-aos-duration="1000"
        >
          <div ref={footerRef}>
            <p className="text-[#FFD38C] text-center text-xl md:text-4xl font-extrabold">
              {isInViewport && <CountUp start={0} end={50} duration={2} />}+
            </p>
            <p className="text-[#FFD38C] text-center text-lg md:text-2xl">
              Events
            </p>
          </div>
          <div>
            <p className="text-[#FFD38C] text-center text-2xl md:text-4xl font-extrabold">
              {isInViewport && <CountUp start={0} end={15000} duration={2} />}+
            </p>
            <p className="text-[#FFD38C] text-center text-lg md:text-2xl">
            Footfalls
            </p>
          </div>
          <div>
            <p className="text-[#FFD38C] text-center text-2xl md:text-4xl font-extrabold">
              {isInViewport && <CountUp start={0} end={3} duration={3} />}
            </p>
            <p className="text-[#FFD38C] text-center text-lg md:text-2xl">Days</p>
          </div>
          <div>
            <p className="text-[#FFD38C] text-center text-2xl md:text-4xl font-extrabold">
              {isInViewport && <CountUp start={0} end={5} duration={3} />}+
            </p>
            <p className="text-[#FFD38C] text-center text-lg md:text-2xl">
              Artists
            </p>
          </div>
        </div>
        
        <div className="bg-[#212122] flex flex-col items-center" data-aos="fade-up" >
          <p className="text-center text-[#ffd38c] text-4xl pt-24 font-bold uppercase" >
            About
          </p>
         
        
          {isWide ? (
            <>
            <p className="text-[#ffd38c] text-center px-[40px] sm:px-28 py-20 text-lg " style={{ color: "silver", fontFamily: "Optima, sans-serif", fontWeight:"bold", fontSize:"20px"}}>
              HBTU Kanpur not only fosters academic excellence, but also promotes an
              environment full of abundant opportunities for its students to showcase
              their inner talent. The Cultural Sub-Council of the University presents
              ADHYAAY, a three days annual celebration of cultural diversity, artistic
              expression, and community spirit. The fest aims to serve a vibrant platform
              for fostering interethnic exchange, innovation and inclusivity, and encouraging
              students to explore themselves and the college life. We invite you to join us on
              this journey of discovery, connection, and celebration.
              </p>
            </>
            
          ) : (
            <>
            <p className="text-[#ffd38c] text-center px-[40px] sm:px-28 py-20 text-lg " style={{ color: "silver",  fontFamily: "Optima, sans-serif,Verdana", fontWeight:"bold", fontSize:"20px"}}>
              HBTU Kanpur not only fosters academic excellence, but also promotes an
              environment full of abundant opportunities for its students to showcase
              their inner talent. The Cultural Sub-Council of the University presents
              ADHYAAY, a three days annual celebration of cultural diversity, artistic
              expression, and community spirit.
              
              
              </p>
            </>
          )}
         
         
        </div>
        <div className="Gallery" data-aos="fade-up">
          <p className="text-center text-[#ffd38c] text-2xl md:text-4xl pt-5 pb-5 font-bold uppercase" >
            Gallery : : Past Memories
          </p>
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={4000}
            stopOnHover={false}
            emulateTouch={true}
            dynamicHeight={false}
            showArrows={true}
            showIndicators={true}
            swipeable={true}
            transitionTime={1000}
            height="500px" >
            <div className="h-full bg-red-500">
              <img src='/Gallery/scene1.jpg' alt="slide1" className="object-cover w-full h-full" style={{ maxHeight: '650px' }} />

            </div>
            <div>
              <img src='/Gallery/scene2.jpg' alt="slide2" className="object-cover w-full h-full" style={{ maxHeight: '650px' }} />

            </div>
            <div>
              <img src='/Gallery/scene3.jpg' alt="slide3" className="object-cover w-full h-full" style={{ maxHeight: '650px' }} />
            </div>
            <div>
              <img src='/Gallery/scene4.jpg' alt="slide3" className="object-cover w-full h-full" style={{ maxHeight: '650px' }} />
            </div>
          </Carousel>
        </div>
        

        <div
          className=" flex flex-col items-center footer border-t-2 border-[#FFD38C]"
          style={{ backgroundColor: "black" }}
        >
          <p className="text-center text-[#ffd38c] text-4xl pt-24 font-bold uppercase">
            Tentative Celebrity
          </p>

          {isWide ? (
            <>
              <div className="flex flex-col flex-wrap min-w-[100%] md:flex-row justify-around items-center md:pt-16 overflow-hidden  md:mb-10">
                <Parallax translateX={['150px', '-150px']} translateY={['30px', '-30px']} className="flex min-w-[100%] gap-2 justify-around">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/djkashish.png'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Dj Kashish</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/jonitagandhi.jpg'
                        width={290}
                        className="object-cover h-full w-full  hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Jonita Gandhi</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/maninder.jpg'
                        width={290}
                        className="object-cover w-full pt-16 hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Maninder Buttar</h1>
                  </div>
                </Parallax>
              </div>
              <div className="flex flex-col flex-wrap min-w-[100%] md:flex-row justify-around items-center md:pt-16 overflow-hidden mb-10">
                <Parallax translateX={['-150px', '150px']} translateY={['30px', '-30px']} className="flex flex-wrap min-w-[100%] justify-around">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/sachetparampara.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Sachet Parampara</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/pallavi.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Pallavi Yadav</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/kaul.png'
                        width={290}
                        className="object-cover w-full h-full scale-[1.9] hover:scale-[1.99] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Vyomesh Koul</h1>
                  </div>
                </Parallax>
              </div>
              <div className="flex flex-col flex-wrap min-w-[100%] md:flex-row justify-around items-center md:pt-16 overflow-hidden  md:mb-10">
                <Parallax translateX={['150px', '-150px']} translateY={['30px', '-30px']} className="flex min-w-[100%] gap-2 justify-around">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/camilla.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Dj Camilla</h1>
                  </div>
                </Parallax>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col flex-wrap min-w-[100%] overflow-hidden items-center"  >
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/jonitagandhi.jpg'
                        width={290}
                        className="object-cover h-full w-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Jonita Gandhi</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['-150px', '150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/maninder.jpg'
                        width={290}
                        className="object-cover w-full pt-16 hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Maninder Buttar</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/sachetparampara.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Sachet Parampara</h1>
                  </div>
                </Parallax>
              </div>
              <div className="flex flex-col flex-wrap min-w-[100%] overflow-hidden items-center">
                <Parallax translateX={['-150px', '150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/djkashish.png'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Dj Kashish</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/pallavi.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Pallavi Yadav</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['-150px', '150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/kaul.png'
                        width={290}
                        className="object-cover w-full h-full scale-[1.9] hover:scale-[1.99] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Vyomesh Koul</h1>
                  </div>
                </Parallax>
              </div>
              <div className="flex flex-col flex-wrap min-w-[100%] overflow-hidden items-center"  >
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/camilla.jpg'
                        width={290}
                        className="object-cover h-full w-full hover:scale-[1.05] transition-all ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Dj Camilla</h1>
                  </div>
                </Parallax>
              </div>
            </>
          )}

          <p className="text-center text-[#ffd38c] text-4xl pt-24 font-bold uppercase">
            Past Celebrity
          </p>
          {isWide ? (
            <>
              <div className="flex flex-col flex-wrap min-w-[100%] md:flex-row justify-around items-center md:pt-16 overflow-hidden  md:mb-10">
                <Parallax translateX={['150px', '-150px']} translateY={['30px', '-30px']} className="flex min-w-[100%] gap-2 justify-around">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/darshan.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Darshan Raval</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/vishalshekahr.jpg'
                        width={290}
                        className="object-cover w-full h-[80%] hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Vishal Shekhar</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/rahul.jpg'
                        width={290}
                        className="object-cover w-full pt-10 scale-[1.2] hover:scale-[1.25] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Rahul Jain</h1>
                  </div>
                </Parallax>
              </div>
              <div className="flex flex-col flex-wrap min-w-[100%] md:flex-row justify-around items-center md:pt-16 overflow-hidden mb-10">
                <Parallax translateX={['-150px', '150px']} translateY={['30px', '-30px']} className="flex flex-wrap min-w-[100%] justify-around">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/djrhea.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Dj Rhea</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/yaseer.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Yaseer Desai</h1>
                  </div>
                  <div className="flex flex-col justify-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] md:h-[320px] md:w-[320px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center">
                      <img
                        src='/Celebs/time.jpg'
                        width={290}
                        className="object-cover w-full pt-10 hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Samay Raina</h1>
                  </div>
                </Parallax>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col flex-wrap min-w-[100%] overflow-hidden items-center">
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/darshan.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Darshan Raval</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['-150px', '150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/vishalshekahr.jpg'
                        width={290}
                        className="object-cover w-full h-[80%] hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Vishal Shekhar</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/rahul.jpg'
                        width={290}
                        className="object-cover w-full pt-10 scale-[1.2] hover:scale-[1.25] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Rahul Jain</h1>
                  </div>
                </Parallax>
              </div>
              <div className="flex flex-col flex-wrap min-w-[100%] overflow-hidden items-center">
                <Parallax translateX={['-150px', '150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/djrhea.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Dj Rhea</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['150px', '-150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/yaseer.jpg'
                        width={290}
                        className="object-cover w-full h-full hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Yaseer Desai</h1>
                  </div>
                </Parallax>
                <Parallax translateX={['-150px', '150px']} className="flex flex-col items-center">
                  <div className="flex flex-col justify-center items-center my-6 md:my-0">
                    <div className="h-[290px] w-[290px] rounded-3xl border-4 border-[#ffd38c] overflow-hidden flex items-center justify-center ">
                      <img
                        src='/Celebs/time.jpg'
                        width={290}
                        className="object-cover w-full pt-10 hover:scale-[1.05] transi ease-in-out duration-100"
                      />
                    </div>
                    <h1 className="text-center text-3xl text-[#ffd38c] mt-4 font-semibold">Samay Raina</h1>
                  </div>
                </Parallax>
              </div>
            </>
          )}
        </div>
      </div>
    </>

  );
};

export default Home;
