


export const Developers = [
  {
    "Timestamp": "12/02/2024 12:25:57",
    "Name:": "Yash Lawaniya",
    "Linkedln": "https://www.linkedin.com/in/yash-lawaniya-51957a215/",
    "Instagram": "https://www.instagram.com/lawaniya_yashhh/",
    "IMAGE_ID": "1uckU6GpRKJD2vnsKDley6slx9vfnFnMw",
    "Designation": "Web Dev. Head"
  },
  {
    "Timestamp": "16/02/2024 16:47:23",
    "Name:": "Bipin Singh",
    "Linkedln :": "https://www.linkedin.com/in/bipin-singh-24a9b0226?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    "Instagram :": "https://www.instagram.com/bipinyct?igsh=MTd3MmtwYjdzdnV0dw%3D%3D&utm_source=qr",
    "IMAGE_ID": "1xV-F1BAbW9l8E7PVPet9z-YDf2c0Lwoi",
    "Designation": "Web Dev. Head"
  },
  {
    "Timestamp": "2023/04/09 1:16:19 AM GMT+5:30",
    "Name:": "Ankit Lekhak",
    "Linkedln :": "www.linkedin.com/in/ankit-l-8b29ba131",
    "Instagram :": "https://www.instagram.com/",
    "IMAGE_ID": "1GaC19p_pKTckQBJGKbZK6-O66p9vvAT-",
    "Designation": "Web Dev. Head",
  },
  {
    "Timestamp": "2024/03/09 09:33:33 PM GMT+5:30",
    "Name:": "Ayush Chaudhary",
    "Designation": "Web Dev. Head",
    "Linkedln :": "https://www.linkedin.com/in/ayush--chaudhary?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/ayush.panwar____/",
    "IMAGE_ID": "1Z-gp99I2DQO0b-gnoBzurgrEC3tVXfoX"
  },
  {
    "Timestamp": "15/02/2024 17:34:34",
    "Name:": "Priyanshu Yadav",
    "Linkedln :": "https://www.linkedin.com/in/priyanshuhbti?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/priyanshusgvm/",
    "IMAGE_ID": "1szeNYDJ1QP7wNXgyP9Sqer1HjQN21m8U",
    "Designation": "Associate Head Web Dev."
  },
  {
    "Timestamp": "15/02/2024 17:07:21",
    "Name:": "Sumit Singh",
    "Linkedln :": "https://www.linkedin.com/in/sumit-singh-developer?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/rustygenius_98?igsh=cHJsODdmZzVsYW14",
    "IMAGE_ID": "1sr9iEW2_EWwk290AuOi5-5l0w5_KT9Gc",
    "Designation": "Associate Head Web Dev."
  },

];

export const ofc = [
  {
    "Timestamp": "2024/02/12 02:11:53 PM GMT+5:30",
    "Name:": "Devvrat Singh",
    "Designation": "Overall Fest Coordinator",
    "Instagram :": "https://www.instagram.com/rajput__devvrat?igsh=eDg3dm5yZm9xcXU2",
    "Linkedln :": "https://www.linkedin.com/in/devvrat-singh-3b3595226?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "IMAGE_ID": "1ywF8obZYBcxU577zOpCECv69dtUViSbQ"
  },
  {
    "Timestamp": "2024/03/08 11:05:31 PM GMT+5:30",
    "Name:": "Harsh Maddheshiya",
    "Designation": "Overall Fest Coordinator",
    "Instagram :": "https://www.instagram.com/bas_maddheshiya?igsh=MW8xbGMxNnFzZm52dg==",
    "Linkedln :": "https://www.linkedin.com/in/harsh-maddheshiya-908a60257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "IMAGE_ID": "1Kbdfg9yJMvZrfw3tHJvWXL4r_F8UmMVz"
  },

  {
    "Timestamp": "2024/03/09 02:48:31 PM GMT+5:30",
    "Name:": "Abhay Sonker",
    "Designation": "Overall Fest Coordinator",
    "Instagram :": "https://www.instagram.com/_abhaysonker_?igsh=MW13a3dodWwwazkzag==",
    "Linkedln :": "https://www.linkedin.com/in/abhay-kumar-sonker-3940701b4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "IMAGE_ID": "1lRX-oUHYzr_hxn-iIcXDWaVu9QGogz0m"
  },
  {
    "Timestamp": "2024/03/09 02:48:31 PM GMT+5:30",
    "Name:": "Nishant Gola",
    "Designation": "Overall Fest Coordinator",
    "Instagram :": "https://www.instagram.com/nishant_gola?igsh=OGo4a3Q2cGw5aWJv&utm_source=qr",
    "Linkedln :": "https://www.linkedin.com/in/nishant-gola-302622210?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    "IMAGE_ID": "1Je3a3tj2CHLX00z9xEMHmJV8LbB1aw8k"
  },
];

export const sec = [
  {
    "Timestamp": "29/03/2024 11:08:23 AM GMT+5:30",
    "Name:": "Nishchhal Nigam",
    "Linkedln :": "https://www.linkedin.com/in/nishchhal-nigam-307094203?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/nishchhal_nigam?igsh=MXMxejVobTFheHVscA==",
    "IMAGE_ID": "1rE-aLKdbl252BwQGW99urmO_dmCaU9_x",
    "Designation": "STUDENT SECRETARY"
  },

];

export const heads = [
  {
    "Timestamp": "2024/01/21 11:14:18 AM GMT+5:30",
    "Name:": "Garvit Mohan Sharma",
    "Designation": "Content Head",
    "Linkedln :": "https://www.linkedin.com/in/garvit-mohan-sharma-bbb961235?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/garvit_._sharma?igsh=cjJsNTl2MGZsejR1",
    "IMAGE_ID": "1mrNVHiDnsPXYt6A3O3o1ejOJsiVjX1Mv"
  },
  {
    "Timestamp": "2024/03/09 02:22:09 PM GMT+5:30",
    "Name:": "Arpit Kumar",
    "Designation": "Content Head",
    "Linkedln :": "https://www.linkedin.com/in/arpit-k?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/ar0.3k?igsh=MTUzNXBhNTM3MXVuMA==",
    "IMAGE_ID": "1B19QfvDiL4ElWByt95XlQrLezQy_hxaA"
  },
  {
    "Timestamp": "2024/03/09 02:45:05 PM GMT+5:30",
    "Name:": "Aryan Dubey",
    "Designation": "Events Head",
    "Linkedln :": "https://www.linkedin.com/in/aryan-dubey-abbb02229?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/aryan_681_?igsh=MW1jcmRwbHl4OWI2Nw==",
    "IMAGE_ID": "18wCxJiwTaBBNddJY2mQrERGTXIe5Zh_j"
  },
  {
    "Timestamp": "2024/03/09 02:45:05 PM GMT+5:30",
    "Name:": "Chaman Singh Narwar",
    "Designation": "Events Head",
    "Linkedln :": "https://www.linkedin.com/in/chaman-singh-narwar-99840b234?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/chamansingh2181?igsh=MWlveDZzM2ZyZWxhZw==",
    "IMAGE_ID": "14UjyZalomfqjAyFGR-BJO5hl_ac8oumg"
  },
  {
    "Timestamp": "2024/01/29 05:45:49 PM GMT+5:30",
    "Name:": "Saharsh Pande",
    "Designation": "Informals Head",
    "Linkedln :": "https://www.linkedin.com/in/saharsh-pande-a1b904195",
    "Instagram :": "https://www.instagram.com/saharshinnnit/",
    "IMAGE_ID": "1BeQ33CQoUeS2c2q7IUUIAgsImUmux4WM"
  },
  {
    "Timestamp": "2024/03/09 02:26:32 PM GMT+5:30",
    "Name:": "Abhishek Srivastava",
    "Designation": "Design Head",
    "Linkedln :": "https://www.linkedin.com/in/abhishek-srivastava-34042622a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/abhishek95590?igsh=enJxODZrb3ZiMmpq",
    "IMAGE_ID": "1sC8f6lerBF7mfT52i9IEojjirtJ_gbX0"
  },
  {
    "Timestamp": "23/03/2024 16:44:56",
    "Name:": "Hemant Pathak",
    "Designation": "Hospitality Head",
    "Linkedln :": "https://www.linkedin.com/in/hemant-pathak-863033224?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/invites/contact/?i=aui5tvfwk6ne&utm_content=ommifi5",
    "IMAGE_ID": "1Zn9ho4yGcHakwrAZFcFkVDsv7YufsFwI"
  },
  {
     "Timestamp": "2024/03/09 02:45:05 PM GMT+5:30",
    "Name": "Samarjeet Singh",
    "Designation": "Stage Management Head",
    "Instagram": "https://www.instagram.com/thesamarjs_?igsh=M2Q2eHg1cmNiNjVy",
    "Linkedln": "https://www.linkedin.com/in/samarjeet-singh9759",
    "IMAGE_ID": "1xfpkHMptbBNplFiZshAWQ_ErOSuRHUsw"
  },
  {
     "Timestamp": "2024/03/09 02:45:05 PM GMT+5:30",
    "Name": "Ayush Tripathi",
    "Designation": "Stage Management Head",
    "Instagram": "https://www.instagram.com/_ayush_tripathi?igsh=OW5tcXBlcjk4Z294",
    "Linkedln": "https://www.linkedin.com/in/ayush-tripathi-38a092233?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "IMAGE_ID": "13SEIlCNAB-qGb-JCCwr8gYWARH0I73FI"
  },
  {
    "Timestamp": "23/03/2024 16:44:56",
    "Name:": "Mayank Agarwal",
    "Designation": "Logistics and Hospitality Head",
    "Linkedln :": "https://www.linkedin.com/in/mayank-goyal-051619197",
    "Instagram :": "https://www.instagram.com/invites/contact/?i=ur67sjq840nu&utm_content=2nwnn5k",
    "IMAGE_ID": "1Xx0ZwGOFYOzgemNNhmUUU6fZ1eDf2fxK"
  },
  {
    "Timestamp": "23/03/2024 16:44:56",
    "Name:": "Tanishq Singh",
    "Designation": "Marketing Head",
    "Linkedln :": "https://www.linkedin.com/in/tanishq-singh-b45121253?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    "Instagram :": "https://www.instagram.com/_tanishq_1_0?igsh=dGtpdXNnNWc3cGZ4",
    "IMAGE_ID": "1RVM0Prmu3qtcQ46MMd-F0cmNtWQPYcXL"
  },
  {
    "Timestamp": "2024/03/09 09:39:15 PM GMT+5:30",
    "Name:": "Ruchi Singh",
    "Designation": "Stage Management Head",
    "Instagram :": "https://www.instagram.com/ruhisingh1066?igsh=MWpnd3lxejdqcDRsbg%3D%3D&utm_source=qr",
    "Linkedln :": "http://linkedin.com/in/ruchi-singh-9836b52a6",
    "IMAGE_ID": "14gFC19FmlnL27AE0eEbuvncis09R_plj"
  },


];

