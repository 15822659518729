/* eslint-disable jsx-a11y/alt-text */
import './EventDetail.css'
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Bounce, Roll, Slide, Zoom } from 'react-swift-reveal';
const EventDetail = () => {
  const route = useLocation();
  const { des, name, img } = route.state;

  return (
    <div
      className="p-10 "
      style={{
        backgroundColor: "black",
        backgroundPosition: "center",
        objectFit: "scale-down",
        minHeight: "100vh",
        overflowY: "hidden",
        paddingTop:'20vh',
        justifyContent: "center",
      }}
    >
      <Slide left>
        <h1 style={{ color: "#dbba89", marginTop: 20 }} className="text-center text-4xl lg:text-6xl font-semibold font-serif tracking-wide">{name}</h1>
      </Slide>
      <div style={{justifyContent: 'center',alignContent:'center',alignItems:'center'}} className='flex flex-col pb-[30px] lg:min-h-[50vh] lg:flex-row' >
        <div className='box' style={{marginTop:"100px"}}>
          <Zoom>
            <img src={img} className="eventImg" />
          </Zoom>
        </div>
        <div className="w-[95%] lg:w-2/3 pt-[5vh] lg-pt-[6vh]  flex flex-col items-center event-details-right-div">
          <Bounce delay={200}> 
            <p
              className="text-lg lg:text-2xl p-2 pt-4 lg:p-[30px] text-[grey] lg:mt-10 w-full lg:w-[85%]"
            >
              {des}
            </p>
          </Bounce>
        </div>      
      </div>
    </div>
  );
};

export default EventDetail;
